<template>
  <moe-page title="查看预售活动">
    <moe-card :autoHeight="true">
      <moe-steps :active="active" :stepsList="stepsList"></moe-steps>

      <div class="df aic jcc mt-60 mb-10">
        <div class="mr-20">
          <moe-icon name="reminder" size="30px"></moe-icon>
        </div>
        <div class="font-16">
          <div class="mb-10 wsn">1.预售活动发布后，活动期间商品将不能下架及修改，活动不可中途关闭，请确认优惠信息设置无误。</div>
          <div class="wsn">2.活动发布后未到活动时间商品进入预热期，不可购买或支付定金。到达活动时间后自动开启预售。</div>
        </div>
      </div>

      <moe-describe-list title="" :col="2">
        <moe-describe-item label="活动名称"> {{ presaleParams.name }} </moe-describe-item>
        <moe-describe-item label="预售方式"> 预售立减 </moe-describe-item>
        <moe-describe-item label="优惠级别"> {{ $moe_format.getPresaleLevel(presaleParams.level) }} </moe-describe-item>
        <moe-describe-item label="创建时间"> {{ createTime }} </moe-describe-item>
        <moe-describe-item label="定金支付时间">{{ presaleParams.depositStartTime }} 至 {{ presaleParams.depositEndTime }} </moe-describe-item>
        <moe-describe-item label="尾款支付时间"> {{ presaleParams.balanceStartTime }} 至 {{ presaleParams.balanceEndTime }}</moe-describe-item>
      </moe-describe-list>


      <div style="height: 700px;min-height: 700px;" class="df fdc">
        <moe-table
          :stripe="true"
          :numberShow="false"
          :data="presaleParams.configList"
          :mode="false"
          :showPage="false"
          :params="{ pageNum: 1 }"
          emptyText="未添加预售活动商品"
          rowKey="goodsId">

          <el-table-column label="商品信息" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic">
                <moe-image :src="row.coverUrl" width="80px" height="80px" />
                <div style="text-align:left" class="ml-10">
                  <p class="fwb">{{ row.name }}</p>
                  <p class="font-12 color-info">商品ID: {{ row.goodsId }}</p>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="单价" min-width="200">
            <template slot-scope="{ row }">
              {{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}
            </template>
          </el-table-column>

          <el-table-column label="预售优惠" min-width="200" v-if="presaleParams.level === 'GOOD'">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <div class="mr-5">立减</div>
                <div>{{ `¥ ${row.reduced}` }}</div>
              </div>
            </template>
          </el-table-column>

          <template v-if="presaleParams.level === 'SKU'">
            <el-table-column label="优惠方式" min-width="200">
              <template slot-scope="{ row, $index }">
                <div class="df fdc aic mt-20">
                  <div>预售立减</div>
                  <el-button
                    v-if="$moe_math.mathGreaterThan(row.presaleMinPrice, 0) && $moe_math.mathGreaterThan(row.presaleMaxPrice, 0)"
                    @click="queryShopGoodsById(row, $index)"
                    icon="el-icon-circle-check"
                    type="text">
                    预售价格设置
                  </el-button>
                  <el-button
                    v-else
                    @click="queryShopGoodsById(row, $index)"
                    icon="el-icon-thumb"
                    type="text">
                    预售价格设置
                  </el-button>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="优惠前后价格" min-width="300">
              <template slot-scope="{ row }">
                <div class="df fdc aic">
                  <div class="line-through dib">原售价：{{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}</div>
                  <div>预售价：{{ $moe_math.mathGreaterThan(row.presaleMinPrice, 0) ? `¥ ${row.presaleMinPrice}${$moe_math.mathGreaterThan(row.presaleMaxPrice, row.presaleMinPrice) ? ` - ¥ ${row.presaleMaxPrice}` : ''}` : '-' }}</div>
                </div>
              </template>
            </el-table-column>
          </template>

          <template v-if="presaleParams.level === 'GOOD'">
            <el-table-column label="优惠前后价格" min-width="200">
              <template slot-scope="{ row }">
                <div class="line-through dib">原售价：{{ `¥ ${row.minPrice}` }}{{ $moe_math.mathGreaterThan(row.maxPrice, row.minPrice) ? ` - ¥ ${row.maxPrice}` : '' }}</div>
                <div>预售价：{{ `¥ ${$moe_math.mathSub(row.minPrice, row.reduced)}` }}{{ $moe_math.mathGreaterThan($moe_math.mathSub(row.maxPrice, row.reduced), $moe_math.mathSub(row.minPrice, row.reduced)) ? ` - ¥ ${$moe_math.mathSub(row.maxPrice, row.reduced)}` : '' }}</div>
              </template>
            </el-table-column>

            <el-table-column label="预付定金" min-width="200">
              <template slot-scope="{ row }">
                <div class="df aic jcc">
                  <div class="mr-5">预付</div>
                  <div>{{ `¥ ${row.deposit}` }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column label="付款金额明细" width="250">
              <template slot-scope="{ row }">
                <div class="df fdc aic mt-20">
                  <div>定金支付：{{ $moe_math.mathGreaterThan(row.deposit, 0) ? `￥ ${row.deposit}` : '-' }}</div>
                  <div>尾款支付：{{ `¥ ${$moe_math.mathSub(row.minPrice, row.balancePayment)}` }}{{ $moe_math.mathGreaterThan($moe_math.mathSub(row.maxPrice, row.balancePayment), $moe_math.mathSub(row.minPrice, row.balancePayment)) ? ` - ¥ ${$moe_math.mathSub(row.maxPrice, row.balancePayment)}` : '' }}</div>
                </div>
              </template>
            </el-table-column>
          </template>
        </moe-table>
      </div>

      <div class="df aic jcc mt-30">
        <el-button type="primary" plain icon="el-icon-back" @click="$moe_coordinator.navigateBack()">返回列表</el-button>
      </div>
    </moe-card>

    <moe-dialog :show="showDialog" title="SKU优惠设置" width="70%" @close="handleDialogClose()">
      <div class="dialog_content">
        <moe-table
          url=""
          :maxHeight="480"
          :data="configList"
          :number-show="false"
          :mode="false"
          :params="{ pageNum: 1 }">
          <el-table-column label="商品编号" prop="goodsId" width="100" />

          <el-table-column label="SKU" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <moe-tag class="mr-5" v-for="(item, index) in row.specValueStr.split(',')" :key="index">{{ item }}</moe-tag>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="库存" prop="stock" min-width="100" />

          <el-table-column label="预售优惠" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <div class="mr-5">立减</div>
                <div>{{ `¥ ${row.reduced}` }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="优惠前后价格" min-width="200">
            <template slot-scope="{ row }">
              <div class="df fdc aic jcc">
                <div class="line-through dib">原售价：￥{{ row.salePrice }}</div>
                <div>预售价：{{ $moe_math.mathGreaterThan(row.reduced, 0) ? `￥${$moe_math.mathSub(row.salePrice, row.reduced)}` : '-' }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="预付定金" min-width="200">
            <template slot-scope="{ row }">
              <div class="df aic jcc">
                <div class="mr-5">预付</div>
                <div>{{ `¥ ${row.deposit}` }}</div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="付款金额明细" min-width="250">
            <template slot-scope="{ row }">
              <div class="df fdc aic jcc">
                <div>定金支付：{{ $moe_math.mathGreaterThan(row.deposit, 0) ? `￥${row.deposit}` : '-' }}</div>
                <div>尾款支付：{{ `￥ ${$moe_math.mathSub(row.salePrice, row.balancePayment)}` }}</div>
              </div>
            </template>
          </el-table-column>
        </moe-table>
      </div>
      <template slot="footer">
        <el-button @click="handleDialogClose()">关闭</el-button>
      </template>
    </moe-dialog>
  </moe-page>
</template>

<script>
export default {
  name: 'ActivityManagePresaleDetail',
  data() {
    return {
      stepsList: [{ title: '基本信息设置' }, { title: '设置商品优惠' }, { title: '完成预售活动' }],
      active: 2,
      presaleParams: {
        id: '',
        name: '',
        method: "REDUCED",    // 预售方式,可用值:REDUCED:预售立减
        level: "GOOD",        // 预售级别,可用值:GOOD:商品级别,SKU:SKU级别
        depositStartTime: "", // 定金支付开始时间
        depositEndTime: "",   // 定金支付结束时间
        balanceStartTime: "", // 尾款支付开始时间
        balanceEndTime: "",   // 尾款支付结束时间
        configList: [],
      },
      createTime: '',
      showDialog: false,
      configList: [],
    }
  },
  methods: {
    handleDialogClose() {
      this.showDialog = false;
    },
    /** 查询预售活动详情 */
    getPresaleDetail() {
      if (this.$route.query.id) {
        this.$moe_api.PRESALE_API.getPresaleDetail({ id: this.$route.query.id }).then((data) => {
          if (data.code === 200) {
            let r = data.result;
            this.presaleParams = {
              id: r.id,
              name: r.name,
              method: r.method,
              level: r.level,
              depositStartTime: r.depositStartTime,
              depositEndTime: r.depositEndTime,
              balanceStartTime: r.balanceStartTime,
              balanceEndTime: r.balanceEndTime,
              configList: [],
            }

            this.createTime = r.createTime;

            if (r.configList.length) {
              this.getQueryShopGoodsByIds(r.configList);
            }
          } else {
            this.$moe_msg.error(data.message);
          }
        })
      }
    },
    /** 查询商品详情 */
    queryShopGoodsById(row, index) {
      this.$moe_api.GOODS_API.queryShopGoodsById({ id: row.goodsId }).then((data) => {
        if (data.code === 200) {
          let goodsItem = this.$moe_lodash.cloneDeepData(data.result.goodsItem);
          if (this.presaleParams.configList[index].configList.length) {
            this.configList = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              item.goodsItemId = item.id;
              let findItem = this.presaleParams.configList[index].configList.find(({ goodsItemId }) => item.goodsItemId === goodsItemId);
              return {
                ...item,
                deposit: findItem.deposit || '',
                reduced: findItem.reduced || '',
                balancePayment: this.$moe_math.mathAdd(findItem.reduced, findItem.deposit) || '',
                id: findItem.id || ''
              }
            })
          } else {
            this.configList = goodsItem.filter(({ delFlag }) => delFlag === 0).map((item) => {
              return {
                ...item,
                deposit: '',
                reduced: '',
                balancePayment: '',
                goodsItemId: item.id,
                id: '',
              }
            })
          }
          this.showDialog = true;
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    },
    /** 根据商品ID获取商品列表 */
    getQueryShopGoodsByIds(goodsList) {
      //数组去重
      let unionByList = this.$moe_lodash.unionByList(goodsList, goodsList, 'goodsId')
      this.$moe_api.GOODS_API.getQueryShopGoodsByIds({ ids: unionByList.map(({ goodsId }) => goodsId).join(',') }).then((data) => {
        if (data.code === 200) {
          let result = data.result;
          switch (this.presaleParams.level) {
            case 'GOOD':
              this.presaleParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  balancePayment: this.$moe_math.mathAdd(item.reduced, item.deposit)
                }
              })
              break;
            case 'SKU':
              this.presaleParams.configList = unionByList.map((item) => {
                let findGoodsItem = result.find(({ id }) => item.goodsId === id);
                let configList = goodsList.filter(({ goodsId }) => item.goodsId === goodsId).map((goodsListItem) => {
                  return {
                    ...goodsListItem,
                    balancePayment: this.$moe_math.mathAdd(goodsListItem.reduced, goodsListItem.deposit)
                  }
                })
                return {
                  ...findGoodsItem,
                  goodsId: findGoodsItem.id,
                  ...item,
                  configList: configList.length ? configList : [],
                  presaleMinPrice: this.$moe_math.mathSub(findGoodsItem.minPrice, configList.map(({ reduced }) => Number(reduced)).sort((a, b) => b - a)[0]),
                  presaleMaxPrice: this.$moe_math.mathSub(findGoodsItem.maxPrice, configList.map(({ reduced }) => Number(reduced)).sort((a, b) => a - b)[0])
                }
              })
              break;
          }
        } else {
          this.$moe_msg.error(data.message);
        }
      })
    }
  },
  mounted() {
    this.getPresaleDetail();
  }
}
</script>

<style lang="scss">

</style>